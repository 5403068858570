import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import notchedOutline from '@material-ui/core/OutlinedInput';
import download from 'downloadjs';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useLazyQuery, useMutation } from '@apollo/client';
import RelatedPaymentsContainer from './RelatedPaymentsContainer';
import { getDateHour, showDueAt, showPaidAt } from '../../utils';

import { FormattedMessage, injectIntl } from 'react-intl';

import { toast } from 'react-toastify';

import { Query } from '@apollo/client/react/components';

import GET_ALL_TRANSACTIONS from './GetAllTransactions.gql';
import GET_CREDIT_BY_ID from './GetCreditById.gql';
import EXPORT_ALL_TRANSACTIONS_PAID from './exportAllTransactions.gql';
import GENERATE_REGULARIZATION_PAYMENT_LINK from './GenerateRegularizationPaymentLink.gql';
import { RangeCalendar, TimeRanges } from './RangeCalendar';
import { today } from '@internationalized/date';
import { Search } from '@material-ui/icons';
import replayIcon from '../../../../images/replayIcon.png';
import linkIcon from '../../../../images/linkIcon.png';
import { useDebounce } from '../../../../hooks/useDebounce';
import PaymentLinkDialogMessage from './PaymentLinkDialogMessage';
import { RetryUnpaidInstallmentDialog } from '../../Loans/components/RetryUnpaidInstallmentDialog';
import { environment } from '../../../../environments/environment';

const DEFAULT_ROWS_PER_PAGE = 10;
const retryPaymentFeatureFlag = environment.RETRY_PAYMENT_FEATURE_FLAG;

const toastOptions = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const showNumber = (row, payments, order, orderBy) => {
  let numbers = [];
  if (row.type === 'debt') {
    stableSort(payments, getComparator(order, orderBy)).forEach(line => {
      if (row.id === line.regularizeId) {
        numbers.push(line.nbr);
      }
    });
  }
  if (numbers.length > 0) {
    return numbers.toString();
  }
  return row.nbr?.toString();
};

export const paymentTypes = [
  { label: 'frais', value: 'FEES' },
  { label: 'remboursement', value: 'REFUND' },
  { label: 'décaissement', value: 'DISBURSEMENT' },
  { label: 'mensualité', value: 'INSTALLMENT' },
  { label: 'regularisation automatique', value: 'REGULARIZATION_AUTOMATIC' },
  { label: 'regularisation client', value: 'REGULARIZATION_MANUAL' },
  { label: 'regularisation ops', value: 'REGULARIZATION_OPS' },
];
export const paymentStates = [
  { label: 'échoué(e)', value: 'PAYMENT_FAILED' },
  { label: 'payé(e)', value: 'PAID' },
  { label: 'annulé(e)', value: 'CANCELLED' },
  { label: 'remboursé(e)', value: 'REFUNDED' },
  { label: 'planifié(e)', value: 'SCHEDULED' },
  { label: 'litige', value: 'DISPUTED' },
  { label: 'échoué(e)', value: 'FAILED' },
  { label: 'impayé(e)', value: 'UNPAID' },
];

export const formatPaymentStatus = status => {
  const state = paymentStates.find(s => s.value === status);
  return state ? state.label : '';
};
export const formatPaymentType = status => {
  const type = paymentTypes.find(s => s.value === status);
  return type ? type.label : '';
};

const uniquePaymentStates = Array.from(new Map(paymentStates.map(item => [item.label, item])).values());

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'nbr', numeric: false, disablePadding: false, label: 'Numéro' },
  { id: 'dueAt', numeric: false, disablePadding: false, label: 'Dû le' },
  { id: 'paidAt', numeric: false, disablePadding: false, label: 'Payé le' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Montant' },
  { id: 'penaltiesAmount', numeric: false, disablePadding: false, label: 'Montant de la pénalité' },
  { id: 'state', numeric: false, disablePadding: false, label: 'Statut' },
  {
    id: 'bankAccountName',
    numeric: false,
    disablePadding: false,
    label: 'Reference',
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  margin: {
    marginRight: 20,
    width: '30%',
  },
  exportButton: { marginRight: '5px' },
  paymentLinkButton: {
    backgroundColor: 'transparent',
    color: '#FFF',
    border: 'transparent',
  },
  paymentStatusCell: { minWidth: '150px' },
  paymentStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  replayIcon: {
    maxWidth: '26px',
    height: '26px',
  },
  linkIcon: {
    maxWidth: '26px',
    height: '26px',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { filter, setFilter, intl, status, type, selectedDateRange } = props;

  const [exportAllTransactions] = useLazyQuery(EXPORT_ALL_TRANSACTIONS_PAID, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response?.exportAllTransactions) {
        const filename = response?.exportAllTransactions?.filename;
        const file = response?.exportAllTransactions?.file;
        download(`data:plain/csv;base64,${file}`, filename, 'text/plain');
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    },
  });

  return (
    <Toolbar className={clsx(classes.root)}>
      <Tooltip title="Filtre">
        <IconButton aria-label="Filtre" onClick={() => setFilter(!filter)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        <FormattedMessage id="components.utils.payments" />
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.exportButton}
        onClick={() => {
          exportAllTransactions({
            variables: {
              type: {
                included: type ? type : [],
                excluded: [],
              },
              status: {
                included: status ? status : [],
                excluded: [],
              },
              paidAtInterval: {
                startDate: selectedDateRange?.start ? selectedDateRange.start.toDate() : null,
                endDate: selectedDateRange?.end ? selectedDateRange.end.toDate() : null,
              },
            },
          });
        }}
      >
        EXPORT
      </Button>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  cancelButton: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    width: '366px',
    height: '52px',
    borderWidth: '1px',
    borderRadius: '16px',
    color: '#081433',
    backgroundColor: 'transparent',
  },
  continueButton: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    width: '366px',
    height: '52px',
    borderWidth: '1px',
    borderRadius: '16px',
    color: '#fff',
    backgroundColor: '#081433',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cssLabel: {
    opacity: 0.6,
    '&$cssFocused': {
      color: 'black',
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    [`& .${notchedOutline}, &$cssFocused`]: {
      borderColor: theme.palette.textFieldsOutline,
    },
  },
  filterInput: {
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
    minWidth: '100px',
  },
  selectFilter: {
    height: '55.97px',
  },
  searchFilterInput: {
    flex: 1,
    minWidth: '300px',
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: '8px',
  },
  alignEnd: {
    marginLeft: 'auto',
  },
  chips: {
    height: 'auto',
  },
  repaymentButton: {
    paddingLeft: '48px',
    backgroundColor: 'transparent',
    color: '#FFF',
    border: 'transparent',
  },
  paymentLinkButton: {
    backgroundColor: 'transparent',
    color: '#FFF',
    border: 'transparent',
  },
  paymentStatusCell: { minWidth: '150px' },
  paymentStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  replayIcon: {
    maxWidth: '26px',
    height: '26px',
  },
  linkIcon: {
    maxWidth: '26px',
    height: '26px',
  },
}));

const PaymentsContainer = ({ intl }) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('nbr');
  const [selectedCreditId, setSelectedCreditId] = useState(null);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loanIds, setLoanIds] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [filter, setFilter] = useState(false);
  const [payments, setPayments] = useState([]);
  const [status, setStatus] = useState([]);
  const [type, setType] = useState([]);
  const [datesFilter, setDatesFilter] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [searchId, setSearchId] = useState(null);
  const [paymentURL, setPaymentURL] = useState(null);
  const [retryUnpaidInstallment, setRetryUnpaidInstallment] = useState(false);
  const [showPaymentLink, setShowPaymentLink] = useState(false);
  const [selectedBorrowerId, setSelectedBorrowerId] = useState(null);

  const refetchRef = useRef(null);

  const handleCloseRetryPayment = () => {
    if (refetchRef.current) {
      refetchRef.current();
    }
    setSelectedBorrowerId(null);
    setRetryUnpaidInstallment(false);
  };

  const debouncedSearchId = useDebounce(searchId, 1000);

  useEffect(() => {
    setPage(1);
    setSelectedRow(null);
    setSelectedInstallment(null);
    setSelectedCreditId(null);
    setSelectedBorrowerId(null);
  }, [searchId]);
  useEffect(() => {
    if (!retryUnpaidInstallment) setSelectedInstallment(null);
  }, [retryUnpaidInstallment, setRetryUnpaidInstallment]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event, payments) => {
    if (event.target.checked) {
      setLoanIds(payments.map(n => n.loan));
      return;
    }
    setLoanIds([]);
  };

  const handleClick = (event, item) => {
    setSelectedBorrowerId(item.borrower?.id);
    setSelectedRow(prevSelected => (prevSelected === item.id ? null : item.id));
    setSelectedInstallment(prevSelected => {
      const installmentId = prevSelected === item ? null : item;
      setSelectedCreditId(installmentId ? (item.creditId ?? debouncedSearchId) : null);
      return installmentId;
    });
  };

  const isSelected = id => selectedRow === id;

  const handleChangePage = (event, newPage) => {
    newPage += 1;
    setSelectedCreditId(null);
    setSelectedBorrowerId(null);
    setSelectedInstallment(null);
    setPage(newPage < 1 ? 1 : newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const handleCancelDateRangeFilter = () => {
    setSelectedDateRange({
      start: today().subtract({ days: TimeRanges[30] }),
      end: today(),
    });
    setDatesFilter(e => !e);
  };

  const arrangeStateFilter = arr => {
    let selectedValues = [];
    selectedValues = arr.flatMap(state => {
      if (state === 'FAILED') {
        if (!selectedValues.includes('PAYMENT_FAILED')) {
          return ['FAILED', 'PAYMENT_FAILED'];
        }
        return ['FAILED'];
      }
      return state;
    });
    if (!selectedValues.includes('FAILED')) {
      selectedValues = selectedValues.filter(state => state !== 'PAYMENT_FAILED');
    }

    selectedValues = Array.from(new Set(selectedValues));

    const emptyStringIndex = selectedValues.indexOf('');
    if (emptyStringIndex > -1) {
      selectedValues.splice(emptyStringIndex, 1);
      selectedValues.unshift('');
    }
    return selectedValues;
  };

  const [generateRegularizationPaymentLink] = useMutation(GENERATE_REGULARIZATION_PAYMENT_LINK, {
    onCompleted(res) {
      if (res?.generateRegularizationPaymentLink?.url) {
        setPaymentURL(res.generateRegularizationPaymentLink.url);
        setShowPaymentLink(e => !e);
      }
    },
    onError(err) {
      const [error] = err.graphQLErrors;
      toast.error(error?.message, toastOptions);
    },
  });

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Dialog
        maxWidth={'md'}
        PaperProps={{
          style: {
            borderRadius: '16px',
          },
        }}
        open={datesFilter}
        onClose={() => setDatesFilter(e => !e)}
      >
        <DialogContent>
          <RangeCalendar
            setSelectedDateRange={setSelectedDateRange}
            selectedDateRange={selectedDateRange}
            weekdayStyle="long"
          ></RangeCalendar>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            style={{ paddingBottom: '16px' }}
          >
            <Grid item>
              <button className={classes.cancelButton} onClick={handleCancelDateRangeFilter}>
                Annuler
              </button>
            </Grid>
            <Grid item>
              <button className={classes.continueButton} onClick={() => setDatesFilter(e => !e)}>
                Continuer
              </button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {selectedInstallment && selectedBorrowerId && (
        <RetryUnpaidInstallmentDialog
          open={retryUnpaidInstallment}
          onClose={handleCloseRetryPayment}
          selectedTransaction={selectedInstallment}
          borrowerId={selectedBorrowerId}
        />
      )}

      <PaymentLinkDialogMessage
        url={paymentURL}
        open={showPaymentLink}
        setShowDialogMessage={setShowPaymentLink}
      ></PaymentLinkDialogMessage>
      <Paper className={classes.paper}>
        {filter && (
          <Grid container flexDirection="row" justify="flex-start">
            <FormControl variant="outlined" className={classes.filterInput}>
              <InputLabel
                classes={{
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                }}
                id="select-state-label"
              >
                <FormattedMessage id="components.utils.statut" />
              </InputLabel>
              <Select
                multiple
                name="state"
                labelId="select-state-label"
                value={status}
                onChange={e => {
                  setSelectedCreditId(null);
                  setSelectedBorrowerId(null);
                  setSelectedInstallment(null);
                  setStatus(arrangeStateFilter(e.target.value));
                }}
                className={classes.selectFilter}
                input={<OutlinedInput id="select-multiple-chip" label="Selectionnez un type" />}
                renderValue={selected => {
                  let values = selected.filter(state => state !== 'PAYMENT_FAILED');
                  return (
                    <Box>
                      {values.map(value => {
                        const selectedOption = paymentStates.find(option => option.value === value);
                        return <Chip key={value} label={selectedOption ? selectedOption.label : value} />;
                      })}
                    </Box>
                  );
                }}
              >
                {uniquePaymentStates.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={status.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.filterInput}>
              <InputLabel
                classes={{
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                }}
                id="select-state-label"
              >
                <FormattedMessage id="components.utils.type" />
              </InputLabel>
              <Select
                multiple
                name="type"
                labelId="select-state-label"
                value={type}
                onChange={e => {
                  setSelectedCreditId(null);
                  setSelectedBorrowerId(null);
                  setSelectedInstallment(null);
                  setType(arrangeStateFilter(e.target.value));
                }}
                className={classes.selectFilter}
                input={<OutlinedInput id="select-multiple-chip" label="Selectionnez un statut" />}
                renderValue={selected => (
                  <Box>
                    {selected.map(value => {
                      const selectedOption = paymentTypes.find(option => option.value === value);
                      return <Chip key={value} label={selectedOption ? selectedOption.label : value} />;
                    })}
                  </Box>
                )}
              >
                {paymentTypes.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={type.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              name="date"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.utils.dates" />}
              variant="outlined"
              helperText="Selectionnez les dates de paiement"
              value={
                selectedDateRange
                  ? `${selectedDateRange.start
                      ?.toDate()
                      .toLocaleDateString('en-US')} - ${selectedDateRange.end
                      ?.toDate()
                      .toLocaleDateString('en-US')}`
                  : ''
              }
              onKeyDown={event => {
                if (event.nativeEvent.key === 'Backspace' || event.nativeEvent.key === 'Delete') {
                  setSelectedDateRange(null);
                }
              }}
              onClick={() => {
                setDatesFilter(e => !e);
              }}
            ></TextField>
            <Grid item className={classes.alignEnd}>
              <div className={classes.searchContainer}>
                <TextField
                  name="searchCreditId"
                  className={classes.searchFilterInput}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                    },
                    startAdornment: <Search className={classes.searchIcon} />,
                  }}
                  label={<FormattedMessage id="components.placeholder.searchCreditId" />}
                  variant="outlined"
                  id="custom-css-outlined-input"
                  value={searchId || ''}
                  onChange={e => setSearchId(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
        )}
        <Query
          query={debouncedSearchId ? GET_CREDIT_BY_ID : GET_ALL_TRANSACTIONS}
          fetchPolicy="network-only"
          variables={{
            ...(debouncedSearchId
              ? { creditId: debouncedSearchId }
              : {
                  type: {
                    included: type ? type : [],
                    excluded: [],
                  },
                  status: {
                    included: status ? status : [],
                    excluded: [],
                  },
                  paidAtInterval: {
                    startDate: selectedDateRange?.start ? selectedDateRange.start.toDate() : null,
                    endDate: selectedDateRange?.end ? selectedDateRange.end.toDate() : null,
                  },
                  pagination: {
                    page: page,
                    pageLimit: rowsPerPage,
                  },
                }),
          }}
        >
          {({ data, error, loading, refetch }) => {
            refetchRef.current = refetch;
            if (loading) {
              return <CircularProgress />;
            }
            if (error) {
              if (error.networkError) {
                toast.error(intl.formatMessage({ id: 'components.utils.networkError' }), {
                  position: 'bottom-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                });
              } else {
                toast.error(
                  intl.formatMessage({
                    id: 'components.utils.errorHasOccured',
                  }) + error.message,
                  {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                  },
                );
              }
              return '';
            }

            if (data?.getAllTransactions?.transactions) setPayments(data.getAllTransactions.transactions);
            if (data?.getAllTransactions?.metadata) setCount(data.getAllTransactions.metadata.totalItems);
            if (data?.getCreditById?.transactions) {
              setPayments(data.getCreditById.transactions);
              setSelectedBorrowerId(data.getCreditById.borrowerId);
            }
            if (data?.getCreditById?.metadata) setCount(data.getCreditById.metadata.totalItems);

            return (
              <>
                <EnhancedTableToolbar
                  filter={filter}
                  setFilter={setFilter}
                  payments={payments}
                  selected={selectedRow}
                  setSelected={selectedRow}
                  setLoanIds={setLoanIds}
                  refetch={refetch}
                  status={status}
                  type={type}
                  intl={intl}
                  order={order}
                  orderBy={orderBy}
                  selectedDateRange={selectedDateRange}
                />
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={event => handleSelectAllClick(event, payments)}
                      onRequestSort={handleRequestSort}
                      rowCount={payments.length}
                    />
                    <TableBody>
                      {stableSort(payments, getComparator(order, orderBy))
                        .slice(0, rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={event => handleClick(event, row)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                  name="checkbox"
                                />
                              </TableCell>
                              <TableCell align="left">{row.id}</TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                {formatPaymentType(row.type)}
                              </TableCell>
                              <TableCell align="left">{showNumber(row)}</TableCell>
                              <TableCell align="left">
                                <Tooltip title={getDateHour(row.dueAt)}>
                                  <div>{showDueAt(row)}</div>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left">
                                <Tooltip title={getDateHour(row.paidAt)}>
                                  <div>{showPaidAt(row)}</div>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left">{row.principalAmount?.toString() || '0'}</TableCell>
                              <TableCell align="left">{row.penaltyAmount?.toString() || '0'}</TableCell>
                              <TableCell align="left" className={classes.paymentStatusCell}>
                                <div className={classes.paymentStatus}>
                                  <div>{formatPaymentStatus(row.state)}</div>
                                  {row.state === 'UNPAID' && (
                                    <>
                                      {retryPaymentFeatureFlag === 'ON' && (
                                        <button
                                          className={classes.repaymentButton}
                                          onClick={event => {
                                            event.stopPropagation();
                                            setSelectedInstallment(row);
                                            if (!selectedBorrowerId) setSelectedBorrowerId(row.borrower?.id);
                                            setRetryUnpaidInstallment(e => !e);
                                          }}
                                        >
                                          <img
                                            src={replayIcon}
                                            className={classes.replayIcon}
                                            alt="Manual retry"
                                          />
                                        </button>
                                      )}
                                      <button
                                        className={classes.paymentLinkButton}
                                        onClick={event => {
                                          event.stopPropagation();
                                          generateRegularizationPaymentLink({
                                            variables: {
                                              creditId: row.creditId ?? debouncedSearchId,
                                              installmentId: row.id,
                                            },
                                          });
                                        }}
                                      >
                                        <img src={linkIcon} className={classes.linkIcon} alt="Payment link" />
                                      </button>
                                    </>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell align="left">{row.reference}</TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            );
          }}
        </Query>
      </Paper>
      <FormControlLabel
        name="switch"
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Affichage recentré"
      />
      <RelatedPaymentsContainer intl={intl} creditId={selectedCreditId} />
    </div>
  );
};

export default injectIntl(PaymentsContainer);
